body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #000000;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

div.top_menu {
    text-align: center;
    font-size: 17px;
    overflow: hidden;
}

div.top_menu li {
    border-left: 1px solid #ffffff;
    display: inline;
    padding: 0 8px;
}

div.top_menu li {
    border: none;
    text-decoration: underline;
}

div.top_menu li.first {
    border: none;
    font-weight:  bolder;
    text-decoration: none;
}

div.top_menu a {
    color: darkslateblue;
    text-decoration: none;
}

div.top_menu a:hover {
    text-decoration: underline;
}



#shellWhite {

    height: auto;
    margin: 0 auto;
    position: relative;

    width: auto;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;

}

#shellPrimary {
       height: auto;
       margin: 0 auto;
       position: relative;
       width: auto;
       background: #607D8B;
       border-radius: 16px 16px 16px 16px;
       padding: 16px;
       overflow: hidden;
       box-shadow: 5px 5px 5px #CCC;
}

#shellPrimaryNoPadding {
    height: auto;
    margin: 20px auto;
    position: relative;
    width: 100%;
    background: #607D8B;
    border-radius: 16px 16px 16px 16px;
    padding: 16px;
    overflow: hidden;
    box-shadow: 5px 5px 5px #CCC;
}

.roundrect {
    border-radius: 15px;
    box-shadow: 5px 5px 5px #CCC;
    margin: 0px 16px 16px 16px;
}

.roundrect2 {
    border-radius: 5px;
    /*box-shadow: 5px 5px 5px #CCC;*/
    margin: 0px 0px 0px 0px;
}

.qr_container {
    font-family: 'Open Sans', Bea, sans-serif;
    max-width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#center-screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.comment_text {
    margin-top: 5px;
    margin-left: 5px;
    width: 300px;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 #FFFFFF;
    border-color: -moz-use-text-color #FFFFFF #FFFFFF -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
    resize: none;
    white-space: pre;
}

#title {
    background:#FCF690;
    padding:16px;
}

/* Clear floats after image containers */
.row {
    float: left;
    display: flex;
}

.row_with_vertical_padding {
    float: left;
    display: flex;
    padding: 64px 0px 64px 0px;
}

.row_centered {
    max-width: 100%;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Create three equal columns that sits next to each other */
/*.column {*/
/*    float: left;*/
/*    flex: 33.33%;*/
/*    padding: 5px;*/

/*    max-width: 100%;*/
/*    text-align: center;*/
/*}*/

.column h3 {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    padding: 2px;
    color: black;
    font-weight: bold;
}

.columnSingle {
    float: left;
    /*flex: 50.00%;*/
    flex: 33.33%;
    padding: 5px;

    max-width: 100%;
    text-align: center;
}

.columnSingle h3 {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    padding: 2px;
    color: black;
    font-weight: bold;
}

.columnEmpty {
    float: left;
    flex: 25.00%;
    padding: 5px;
}

.preview {
    padding: 16px;
}

.container h1.policy {
    color: crimson
}

.price_card_text {
    font-family: 'Open Sans', Bea, sans-serif;
    max-width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white;
}

.price_card_text h1 {
    color: white;
}

.price_card_text h3 {
    color: white;
}

.price_card_text h4 {
    color: white;
    font-weight: normal;
}

.subscription_card_text {
    font-family: 'Open Sans', Bea, sans-serif;
    max-width: 100%;
    text-align: left;
    align-items: center;
    justify-content: center;
    color: white;
}

.subscription_card_text h1 {
    color: lightgray;
}

.announce_card_text {
    font-family: 'Open Sans', Bea, sans-serif;
    max-width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 5px 52px 5px 52px;
}

.announce_card_text h1 {
    color: black;
}

.announce_card_text h3 {
    color: black;
    font-weight: normal;
}

.announce_card_text h4 {
    color: black;
    font-weight: normal;
}

.announce_card_text h2 {
    color: crimson;
}


.content_card_text {
    font-family: 'Open Sans', Bea, sans-serif;
    max-width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 5px 52px 5px 52px;
}

.content_card_text h4 {
    color: black;
    font-weight: normal;
}

.content_card_text h1 {
    color: black;
}

.content_card_text h3 {
    color: black;
    font-weight: normal;
}

.content_card_text h2 {
    color: black;
    font-weight: normal;
}

p.normal {
    line-height: 1.2;
}