body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #000000;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif
}

.span{
    color: #dc143c;
}

.container {
    width: 95%;
    max-width: 900px;
    margin: 20px auto
}

.container h1 {
    color: black
}

.container h3 {
    margin: 0 0 2px 0;
    color: #646464;
}

.container h4 {
    margin: 0
}

.container p a {
    color: #646464
}

.container ul {
    padding: 0 0 0 30px;
    margin: 10px 0;
}

.container footer {
    background: #f9f9f9;
    border: solid #e5e5e5;
    border-width: 1px 0;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
}

div.fast_nav {
    text-align: center;
    font-size: 11px;
    overflow: hidden;
}

div.fast_nav li {
    border-left: 1px solid #5c5b5b;
    display: inline;
    padding: 0 7px;
}

div.fast_nav li.first {
    border: none;
}

div.fast_nav a {
    color: #5c5b5b;
    text-decoration: none;
}

div.fast_nav a:hover {
    text-decoration: underline;
}

.container img.phone {
    height: 50%;
    width: 50%;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: left;
}

.container img.tv {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
}