.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html, body {
  font-size: 16px;
  line-height: 1.5rem;
  height: 100%;
  min-height: 100%;
}

.header {
  padding: 5px;
  background: #ECF0F1;
  height: 4em;
  color: #fff;
}

.footer {
  padding: 1rem;
  background: #ECF0F1;
  height: 2rem;
  color: #000000;
}

.content {
  padding-right: 2rem;
  padding-bottom: 1rem;
  background: #ECF0F1;
}

.qr_container {
  font-family: 'Open Sans', Bea, sans-serif;
  max-width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr_paper {
  width: 190px;
  height: 190px;
  object-fit: contain;
  margin:10px;
  display:inline-block;
}

.form_paper{
  width: 350px;
  height: 220px;
  object-fit: contain;
  margin:10px;
  display:inline-block;
}

.qr_paper_top {
  width: 100%;
  height: 3em;
  object-fit: contain;
  margin:1px;
  display:inline-block;
}

li.centered {
  display: flex;
  justify-content:center;
  align-content:center;
}

li.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.top_text {
  font-family: 'Open Sans', Bea, sans-serif;
  max-width: 100%;
  height: 100%;
  margin: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning_text{
  color: crimson;
}

.bottom_left_box{
  left: 0px;
  bottom: 0px;
  position: fixed;
}

.top_menu_container{
  margin-right: 90px;
}

.top_menu {
  padding-right: 15px;
}

#container {
  width:100%;
  text-align:center;
}

#store_qr_container {
  float:bottom;
  background: #ECF0F1;
}

#left {
  float:left;
  width:5em;
}
#left_long {
  float:left;
  display: inline-block;
  margin:0 auto;
  width: 10em;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#center {
  display: inline-block;
  margin:0 auto;
  width:100px;
}

#right {
  display: flex;
  float:right;
  width:6em;
}

#left_image {
  float:left;
  width:10em;
}

#right_image {
  display: flex;
  float:right;
  width:10em;
  margin-right: 20px;
}

.form-control-text {
  text-align: center;
  font-size: 24px;
  overflow: hidden;
}

.form-control-button {
  text-align: center;
  font-size: 24px;
  width:150px;
  overflow: hidden;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.progress {
  height: 10px;
  background-color: #6495ED;
  border-radius: 5px;
  transition: width 0.4s ease;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  margin: 100px;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.0); /* Semi-transparent background */
  z-index: 1000; /* High z-index to be above other elements */
}

.refresh_button {
  position: absolute;
  color: cornflowerblue;
  text-decoration-color: cornflowerblue;
  text-decoration: underline;
  cursor: pointer;
}