.demo {
    position: relative;
}

.placeholder {
    font-family: 'Open Sans', Bea, sans-serif;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}

.photo {
    flex: none;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: 10px;
    display: inline-block;
    object-fit: contain;
}

img:active {
    -webkit-transform: scale(2);
    transform: scale(2);
}

/*img:hover {*/
/*-webkit-transform: scale(2);*/
/*transform: scale(2);*/
/*}*/

.paper_photo {
    width: 98%;
    height: 97%;
    margin: 1% 0.5% 1%;
    display: inline-block;
}

.wait_text {
    color: #e5e5e5;
    flex: none;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
    margin: auto;
    display: inline-block;
    object-fit: contain;
}

.photo_center {
    background: #000000;
    position: relative;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;



    /*background-repeat: no-repeat;*/
    /*background-position: center center;*/
    /*background-attachment: fixed;*/
    /*-webkit-background-size: cover;*/
    /*-moz-background-size: cover;*/
    /*-o-background-size: cover;*/
    /*background-size: cover;*/
}

.close_button_shadow {
    position: absolute;
    color: lightgrey;
    text-decoration-color: lightgrey;
    top:2px;
    right:22px;
    cursor: pointer;
}
.close_button {
    position: absolute;
    color: crimson;
    text-decoration-color: crimson;
    top:0px;
    right:20px;
    cursor: pointer;
}

@font-face {
    font-family: Brankovic;
    src: url('fonts/brankovic.ttf');
}

@font-face {
    font-family: Tiza;
    src: url('fonts/tiza.ttf');
}

@font-face {
    font-family: DuCahier;
    src: url('fonts/TASSELXP.TTF');
}

@font-face {
    font-family: KAUSHAN SCRIPT;
    src: url('fonts/KaushanScript-Regular.otf');
}

@font-face {
    font-family: Alice;
    src: url('fonts/Alice-Regular.ttf');
}

body {
    font-size: 20px;
}

h1 {
    font-family: KAUSHAN SCRIPT, sans-serif;
    font-size: 3em;
}

h2.fine {
    font-family: KAUSHAN SCRIPT, sans-serif;
    font-size: 2em;
}

h3 {
    font-family: Alice, sans-serif;
    font-size: 1em;
}

@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    h1 {
        font-size: 2em;
    }

    h3 {
        font-family: Alice, sans-serif;
        font-size: 1em;
    }
}